









































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import ItemDataService from "@/api/itemdata";

@Component({})
export default class Detalle extends Vue {
  created = false;
  printing = false;
  itemData: any = {};
  detail: any = {
    10: undefined,
    11: undefined,
    12: undefined,
    13: undefined,
    14: undefined,
    choice: undefined,
    vana: undefined,
    broken: undefined,
    broken_pieces: undefined,
    bug: undefined,
    dark: undefined,
    fancy: undefined,
    germinated: undefined,
    item_data_id: this.$route.params.id,
    number_of_nuts: undefined,
    shell: undefined,
    stained: undefined,
    stuffy: undefined,
    with_mold: undefined
  };
  isEditing = false;

  print() {
    const muestra = {
      representante: "Nueces Finas de Santa Rosalia SA de CV",
      lote: "",
      numMuestra: "",
      numCamion: "",
      humedad: `${this.itemData.humidity}%`,
      nuezCascara: {
        gramos: this.detail.shell
      },
      fancy: {
        gramos: this.detail.fancy,
        porcentaje: this.detail.fancy / this.detail.shell
      },
      choice: {
        gramos: this.detail.choice,
        porcentaje: this.detail.choice / this.detail.shell
      },
      enlamada: {
        gramos: this.detail.with_mold,
        porcentaje: this.detail.with_mold / this.detail.shell
      },
      nacida: {
        gramos: this.detail.germinated,
        porcentaje: this.detail.germinated / this.detail.shell
      },
      manchada: {
        gramos: this.detail.stained,
        porcentaje: this.detail.stained / this.detail.shell
      },
      acartonada: {
        gramos: this.detail.stuffy,
        porcentaje: this.detail.stuffy / this.detail.shell
      },
      vana: {
        gramos: this.detail.vana,
        porcentaje: this.detail.vana / this.detail.shell
      },
      chinche: {
        gramos: this.detail.bug,
        porcentaje: this.detail.bug / this.detail.shell
      },
      oscura: {
        gramos: this.detail.dark,
        porcentaje: this.detail.dark / this.detail.shell
      },
      quebradas: {
        gramos: this.detail.broken,
        piezas: this.detail.broken_pieces,
        porcentaje: this.detail.broken / this.detail.shell
      },
      gramosComestible: this.detail.fancy + this.detail.choice,
      porcentajeComestible: this.totalComestible,
      gramosNoComestible:
        this.detail.with_mold +
        this.detail.germinated +
        this.detail.stained +
        this.detail.stuffy +
        this.detail.vana +
        this.detail.bug +
        this.detail.dark,
      porcentajeNoComestible: this.totalNoComestible,
      sizes: {
        c14: this.detail["14"],
        c13: this.detail["13"],
        c12: this.detail["12"],
        c11: this.detail["11"],
        c10: this.detail["10"],
        totalNueces: this.detail.number_of_nuts
      }
    };
    const data = {
      logo1_url: `${window.location.origin}/images/logo.png`,
      logo2_url: `${window.location.origin}/images/logo_nogalera.png`,
      persona: `${this.itemData.name} ${this.itemData.last_name}`,
      idMuestra: `${this.detail.item_data_id}`,
      muestra: muestra,
      fecha: this.itemData.entry_date
    };
    this.printing = true;
    fetch(
      `${process.env.VUE_APP_REPORT_API}/pdf/render/template/control_muestra`,
      {
        method: "POST",
        body: JSON.stringify({ data: data }),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(resp => resp.arrayBuffer())
      .then(resp => {
        //set the blog type to final pdf
        const file = new Blob([resp], { type: "application/pdf" });
        this.printing = false;
        // process to auto download it
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "control_muestra.pdf";
        link.click();
      });
  }

  get calcSizesLeft() {
    const totalSizes = [10, 11, 12, 13, 14].reduce((accum, cur) => {
      const val = this.detail[cur] || 0;
      return accum + val;
    }, 0);
    return this.detail.number_of_nuts - totalSizes;
  }

  updateHumidity() {
    this.$buefy.dialog.prompt({
      message: `¿Cual es la humedad?`,
      inputAttrs: {
        type: "number",
        step: "0.1",
        placeholder: "Ingresa la humedad",
        value: this.itemData.humidity,
        min: 0
      },
      trapFocus: true,
      onConfirm: value => {
        this.itemData.humidity = value;
        ItemDataService.update(this.itemData).then(() => {
          this.$buefy.notification.open({
            message: "Se ha actualizado la humedad",
            type: "is-success"
          });
        });
      }
    });
  }

  get comestible() {
    const data = [
      { shell: "Nuez Cascara" },
      { fancy: "Fancy" },
      { choice: "Choice" }
    ];
    const table: any[] = [];
    data.forEach(field => {
      table.push({
        label: Object.values(field)[0],
        id: Object.keys(field)[0],
        value: this.detail[Object.keys(field)[0]]
      });
    });
    return table;
  }

  get totalComestible() {
    return (this.detail.fancy + this.detail.choice) / this.detail.shell;
  }

  get totalNoComestible() {
    return (
      (this.detail.with_mold +
        this.detail.germinated +
        this.detail.stained +
        this.detail.stuffy +
        this.detail.vana +
        this.detail.bug +
        this.detail.dark) /
      this.detail.shell
    );
  }

  get noComestible() {
    const data = [
      { with_mold: "Enlamada" },
      { germinated: "Nacida (Germinada)" },
      { stained: "Manchada" },
      { stuffy: "Acartonada" },
      { vana: "Vana" },
      { bug: "Chinche" },
      { dark: "Oscura" }
    ];
    const table: any[] = [];
    data.forEach(field => {
      table.push({
        label: Object.values(field)[0],
        id: Object.keys(field)[0],
        value: this.detail[Object.keys(field)[0]]
      });
    });
    return table;
  }

  get sizes() {
    const data = [10, 11, 12, 13, 14, "number_of_nuts"];
    const table: any[] = [];
    data.forEach(field => {
      table.push({
        label: field,
        value: this.detail[field]
      });
    });
    return table;
  }

  get routeName() {
    return this.$route.name;
  }
  get client() {
    if (this.$store.state.ItemModule.activeItem) {
      return (
        this.$store.state.ItemModule.activeItem.client.name +
        " " +
        this.$store.state.ItemModule.activeItem.client.last_name
      );
    }
    return "";
  }
  get stackType() {
    if (this.$store.state.ItemModule.activeItem) {
      return this.$store.state.ItemModule.activeItem.stack_type.name;
    }
    return "Elementos";
  }

  saveDetail() {
    if (!this.detail.number_of_nuts) {
      return this.$buefy.notification.open({
        message: "No se puede agregar una muestra sin número de piezas",
        type: "is-warning"
      });
    }
    if (!this.itemData.stacks && this.routeName == "NuevaMuestra") {
      return this.$buefy.notification.open({
        message: "No se puede agregar una muestra sin número de arpillas",
        type: "is-warning"
      });
    }
    if (this.routeName == "NuevaMuestra") {
      ItemDataService.create(
        parseInt(this.$route.params.idNuez),
        this.itemData
      ).then(response => {
        ItemDataService.createDetail(
          parseInt(response.data.id),
          this.detail
        ).then(() => {
          this.isEditing = false;
          this.$buefy.notification.open({
            message: "Se ha creado una muestra con su detalle",
            type: "is-success"
          });
          this.$router.push({ name: "Nueces" });
        });
      });
    } else {
      this.addToCurrent();
    }
  }

  addToCurrent() {
    if (this.created) {
      ItemDataService.updateDetail(
        parseInt(this.$route.params.id),
        this.detail
      ).then(() => {
        this.isEditing = false;
        this.$buefy.notification.open({
          message: "Se ha actualizado el detalle de la muestra",
          type: "is-success"
        });
      });
    } else {
      ItemDataService.createDetail(
        parseInt(this.$route.params.id),
        this.detail
      ).then(() => {
        this.isEditing = false;
        this.$buefy.notification.open({
          message: "Se ha actualizado el detalle de la muestra",
          type: "is-success"
        });
      });
    }
  }

  mounted() {
    if (this.$route.name != "NuevaMuestra") {
      this.printing = true;
      ItemDataService.getDetail(parseInt(this.$route.params.id)).then(
        response => {
          if (!response) return;
          this.detail = response.data;
          this.created = true;
          this.printing = false;
          console.log("detail", this.detail);
        }
      );
      ItemDataService.get(
        parseInt(this.$route.params.id),
        "humidity, item_data.id, name, last_name, item_data.entry_date"
      ).then(response => {
        this.itemData = response.data;
        this.printing = false;
        console.log("itemdata", this.itemData);
      });
    } else {
      console.log(
        this.itemData,
        this.$route.params.idNuez,
        this.$store.state.ItemModule.activeItem
      );
      if (this.$store.state.ItemModule.activeItem) {
        const mainNueces = this.$store.state.ItemModule.activeItem.item_data.find(
          (x: any) => x.sample === false
        );
        if (mainNueces) {
          this.itemData.quality = mainNueces.quality;
          this.itemData.humidity = mainNueces.humidity;
          this.itemData.stacks = mainNueces.stacks;
          this.itemData.weight = mainNueces.weight;
        }
      }
      this.itemData.sample = true;
      this.itemData.entry_date = new Date();
      this.isEditing = true;
    }
  }
}
